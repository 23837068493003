body{
  font-family: Arial, Helvetica, 
sans-serif;
}

/* Header */
.headerMobile{
  background: rgb(255, 255, 255);
}

.h1Mobile{
    font-size: 2.5rem;
}

/* ICONO */
.btn-wspMobile{
  position: fixed;
  width: 55px;
  height: 55px;
  margin-top: 39rem;
  line-height: 60px;
  background-color: #008000;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  color: rgb(255, 255, 255);
}

/* MENU */
.containerMenuMobile{
  padding: 10px;
}

.menuItemMobile1{
  font-size: 2rem;
  border-bottom: solid 
#000000;
  width: 73%;
  padding: 1rem 2rem 1rem 
2rem;
}

.menuItemMobile1:hover{
  cursor: pointer;
  background: #1cd48d;
  border-radius: 10px;
}

.menuItemMobile{
  font-size: 2rem;
  border-bottom: solid 
#000000;
  width: 73%;
  padding: 1rem 2rem 1rem 
2rem;
}

.menuItemMobile:hover{
  cursor: pointer;
  background: #1cd48d;
  border-radius: 10px;
}

.linkMobile{
  display: none;
}
.logMobile{
  display: none;
}

/* MODAL */
.abrirModalMobile{
  cursor: pointer;
  font-size: 2.5rem;
}

.closeModalMenuMobile{
  cursor: pointer;
  font-size: 2.5rem;
  margin: 0.5rem 0 0 12rem;
  position: absolute;
}

.modalMenuMobile{
  width: 15rem;
  height: 20rem;
  background: #ffffff;
  margin-left: 9.5rem;
  border-radius: 15px 0 0 15px;
  box-shadow: 0 0 30px 
#000000;
}


/* CARDS LUGARES */
.containerCardsMobile{
  border-radius: 1rem;
  width: 100%;
  height: 110rem;
  background-color: rgb(228, 228, 228);
}
.titulolugaresMobile{
  font-size: 1.5rem;
  display: flex;
  justify-content: center;
  padding-top: 4rem;
}
.hrCardLugaresAVisitaM{
  width: 10rem;
  height: 0.2rem;
  margin-left: 7.5rem;
  background-color: #1cd48d;
}
.containerCardsM{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}
.CardciudadIzquierdaM{
  margin-top: 3rem;
  width: 10rem;
  height: 20rem;
}
.CardtitulociudadM{
  font-size: 1.3rem;
  padding: 0%;
  margin-left: 1.5rem;
  display: flex;
}

.LinkMobile{
  width: 20rem;
  margin-top: 2rem;
  height: 10rem;
  color: #ffffff;
  background-color: #162938;
}

/* Testimonios */
.DivCardTestimoniosMobile{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-top: 3rem;
} 
.CardTestimoniosMobile{
  width: 11rem;
  height: 20rem;
}
.hrTestimoniosMobile{
  width: 10rem;
  height: 0.2rem;
  margin-left: 7.5rem;
  background-color: #1cd48d;
}
.imagenTestimonioMobile{
  width: 5rem;
  height: 5rem;
  border-radius: 2rem;
}
.CardImagenMobile{
  border-radius: 1rem 1rem 0 0;
  width: 11rem;
  height: 7rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000000;
}
.CardInformacionTestimonioMobile{
  width: 100%;
  height: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #1cd48d;
  border-radius: 0 0 1rem 1rem;
}
.CardparrafotestimoniosMobile{
  margin-left: 0.5rem;
}

/* Modal Reserva */
.ReservaMobile{
  margin-left: 3rem;
}
.HrReservaMobile{
  width: 10rem;
  height: 0.2rem;
  margin-left: 7.5rem;
  background-color: #1cd48d;
}
.form-box-ReservaMobile{
  margin: 0;
  width: 20rem;
  height: 35rem;
  margin-left: 3rem;
  background-color:#e6e7e6;
  display: flex;
  flex-direction: column;
  margin-top: 5rem;
  border-radius: 1rem;
  border:solid #000000;
  background: rgb(255, 255, 255);
}

.input-boxMobile{
  position: relative;
  border-bottom: 2px solid #162938;
  margin: 30px 0;
  width: 15rem;
  border-radius: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 2rem;
  margin-left: 2rem;
}
.input-boxMobile label{
  position: absolute;
  left: 5px;
  top: 2rem;
  transform: translateY(-50%);
  font-size: 1rem;
  color: #162938;
  font-weight: bold;
  pointer-events: none;
  transition: .7s;
}

.input-boxMobile input:focus~label,
.input-boxMobile input:valid~label {
  top: -5px;
}


.input-boxMobile input{
  width: 100%;
  height: 100%;
  background: transparent;
  border: none;
  outline: none;
  font-size: 1rem;
  color:#162938;
  font-weight: bold;
  padding: 0 35px 0 5px;
  
}

.input-boxMobile .icon{
  position: absolute;
  right: 8px;
  font-size: 1.2rem;
  color: #162938;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
}
.formGroupMobile{
  width: 10rem;
  height: 2rem;
  margin-left: 2.5rem;
}
.btnReservaMobile{
  width: 10rem;
  height: 45px;
  margin-left: 4rem;
  background: #162938;
  border: none;
  outline: none;
  border-radius: 6px;
  cursor: pointer;
  font-size: 1rem;
  color: #ffffff;
  font-weight: bold;
  margin-bottom: 2rem;
}

/* Reserva Modal Interna */
.reservaModaMobile{
    background-color: #c41f1f; 
    width: 20rem;
    height: 55rem;
    /* display: flex; */
    /* margin: 3rem 0 0 15rem; */
    /* border: solid 2px; */
    /* border-radius: 1rem; */
    /* margin-bottom: 10rem; */
    /* box-shadow: 0 0 30px #000000;  */
}
